import { ReactNode } from 'react'

import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Hidden,
  Link as MuiLink,
  Toolbar,
  Typography,
} from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { DataDogRUMProvider, SnackbarProvider } from '@assuranceiq/react-components'

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { StoreProvider } from 'easy-peasy'
import { BrowserRouter as Router, Link } from 'react-router-dom'

import theme from './styles/theme'
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_RESPONSE_TYPE,
  AUTH0_SCOPE,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_VERSION,
  DEPLOY_ENV,
} from './utils/environment-variables'
import { createStore } from './utils/store'

import styles from './App.module.scss'
import assuranceLogoWithName from './images/assurance-logo-name.svg'
import assuranceLogo from './images/assurance-logo.svg'

import './styles/global.scss'

const store = createStore()
export interface DefaultLayoutProps {
  children: ReactNode
}

export const App = function (props: DefaultLayoutProps) {
  const onRedirectCallback = (appState?: { returnTo?: string }) => {
    // redirect to a page where user was before
    if (appState && appState.returnTo) {
      // TODO
      // Router.push({
      //   pathname: appState.returnTo.pathname,
      //   query: appState.returnTo.query
      // })
    }
  }
  return (
    <DataDogRUMProvider
      applicationId={DD_APPLICATION_ID}
      clientToken={DD_CLIENT_TOKEN}
      service={DD_SERVICE}
      env={DEPLOY_ENV}
      sampleRate={DD_SAMPLE_RATE}
      trackInteractions={true}
      version={DD_VERSION}
    >
      <StoreProvider store={store}>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          audience={AUTH0_AUDIENCE}
          responseType={AUTH0_RESPONSE_TYPE}
          scope={AUTH0_SCOPE}
          redirectUri={window.document.location.origin}
          onRedirectCallback={onRedirectCallback}
          post
        >
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <StyledEngineProvider injectFirst>
                <SnackbarProvider>
                  <div className={styles.root}>
                    <Header />
                    <main>{props.children}</main>
                    <Footer />
                  </div>
                </SnackbarProvider>
              </StyledEngineProvider>
            </ThemeProvider>
          </Router>
        </Auth0Provider>
      </StoreProvider>
    </DataDogRUMProvider>
  )
}

function Header() {
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
  const location = window.document.location
  const doLogout = () => {
    logout({ returnTo: location.origin })
  }
  const doLogin = () => {
    loginWithRedirect({
      appState: { returnTo: { pathname: location.pathname, query: location.search.substring(1) } },
    })
  }

  return (
    <AppBar
      position="relative"
      color="default"
      className={styles.appBar}
      style={{
        // inline here to reduce FOUC
        // https://en.wikipedia.org/wiki/Flash_of_unstyled_content
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fafafa',
      }}
    >
      <a href="/">
        <img src={assuranceLogo} alt="Assurance logo" />
      </a>
      <Divider orientation="vertical" flexItem />
      <Toolbar className={styles.toolbar}>
        <Box display="flex" flexDirection="row">
          <Hidden smDown>
            <Link to="/">
              <Button>Home</Button>
            </Link>
            <Link to="/about">
              <Button>About</Button>
            </Link>
            <Link to="/contact">
              <Button>Contact</Button>
            </Link>
          </Hidden>
        </Box>
        <Box display="flex" flexDirection="row">
          {!isLoading &&
            (isAuthenticated ? (
              <Button onClick={doLogout} color="secondary" variant="contained">
                Log out
              </Button>
            ) : (
              <>
                <Button onClick={doLogin} color="secondary" variant="contained">
                  Login
                </Button>
              </>
            ))}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        <Copyright />
        <MuiLink href="https://assurance.com/terms-and-conditions" target="_blank" rel="noopener">
          Terms & Conditions
        </MuiLink>
        <MuiLink href="https://assurance.com/privacy-policy" target="_blank" rel="noopener">
          Privacy Policy
        </MuiLink>
        <MuiLink href="https://join.assurance.com" target="_blank" rel="noopener">
          Become an Agent
        </MuiLink>
      </div>
      <div className={styles.right}>
        <Typography variant="body2" color="textSecondary">
          Powered by
        </Typography>
        <a href="https://assurance.com" target="_blank" rel="noopener noreferrer">
          <img src={assuranceLogoWithName} height={30} alt="Assurance logo" />
        </a>
      </div>
    </footer>
  )
}

function Copyright() {
  return (
    <Typography variant="body2">
      {new Date().getFullYear()}
      {' © '}
      <MuiLink color="inherit" href="https://assurance.com" target="_blank" rel="noopener">
        Assurance IQ
      </MuiLink>
    </Typography>
  )
}
