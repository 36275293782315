import { createStore as createStoreEasyPeasy } from 'easy-peasy'
import { merge } from 'lodash-es'

interface AppModel {}

const appModel: AppModel = {
  // your data
}

export interface StoreModel {
  app: AppModel
}

export const createStore = function (defaults?: StoreModel) {
  const model = {
    app: appModel,
  }

  if (defaults) {
    merge(model.app, defaults.app)
  }

  return createStoreEasyPeasy<StoreModel>(model)
}
