import { createTheme } from '@mui/material/styles'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#0082CB',
    },
    secondary: {
      main: '#FF4237',
    },
    error: {
      main: '#FF0048',
    },
    warning: {
      main: '#FFAD00',
    },
    info: {
      main: '#071F2B',
    },
    success: {
      main: '#00D398',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontSize: 16,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
})

export default theme
