import { lazy, StrictMode, Suspense } from 'react'
import { render } from 'react-dom'

import { Loading } from '@assuranceiq/react-components'

import { Route, Routes } from 'react-router-dom'

import { App } from './App'
import { Example } from './components/Example'

const About = lazy(() => import('./components/About'))
const Contact = lazy(() => import('./components/Contact'))

render(
  <StrictMode>
    <App>
      <Routes>
        <Route path="/" element={<Example>Hello World!</Example>} />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<Loading />}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loading />}>
              <About />
            </Suspense>
          }
        />
      </Routes>
    </App>
  </StrictMode>,
  document.getElementById('root'),
)
