import { lazy, ReactNode, Suspense, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Card, CardActions, CardContent, CardProps, Typography } from '@mui/material'

import styles from './Example.module.scss'

const LazyExample2 = lazy(() => import('../Example2'))

export interface ExampleProps {
  /** Supply any child content to the example */
  children?: ReactNode

  /**
   * Shadow depth.
   *
   * See [MUI Card props](https://mui.com/material-ui/api/paper/#props) for details.
   */
  elevation?: CardProps['elevation']

  /** Text to display in the button */
  buttonText?: string
}

/**
 * An example component to demonstrate styling, testing, and story documentation.
 *
 * Note that the code has [JSDoc comments](https://jsdoc.app/) for the component
 * description and its props—these will be picked up by IDEs, and also appear in
 * the component’s Storybook Docs page.
 */
export const Example = ({ children, buttonText = 'Learn More', elevation = 1 }: ExampleProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const onClick = () => {
    setIsLoading(true)
    console.error('Not implemented')
    setTimeout(() => {
      setIsLoading(false)
      setIsVisible(true)
    }, 2000)
  }

  return (
    <Card className={styles.root} variant="elevation" elevation={elevation}>
      <CardContent>
        <Typography className={styles.title} variant="h6" color="text.secondary" gutterBottom>
          Example
        </Typography>
        {children}
      </CardContent>
      <CardActions>
        <LoadingButton loading={isLoading} onClick={onClick}>
          {buttonText}
        </LoadingButton>
      </CardActions>
      {isVisible && (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyExample2 title="Just another example" />
        </Suspense>
      )}
    </Card>
  )
}
